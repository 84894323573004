import React from 'react';

// styles
import styles from './fade-image.module.scss';

// utils
import { classNameBuilder } from '../../../utils/classNameBuilder';

const TRANSPARENT_PIXEL =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

interface FadeImageProps {
  src: string;
  alt: string;
}

function FadeImage({ src, alt }: Readonly<FadeImageProps>) {
  // the counter is required to keep the key stable to ensure the animation works
  const counter = React.useRef(0);
  const [pipeline, setPipeline] = React.useState([]);

  React.useEffect(() => {
    counter.current += 1;
    setPipeline([
      ...pipeline,
      {
        count: counter.current,
        state: 'added',
        src,
      },
    ]);
  }, [src]);

  React.useEffect(() => {
    let timeouts: Timeout[] = [];
    const pipelineCopy = [...pipeline].filter((img) => img.state !== 'removed');
    pipelineCopy.forEach((img, index) => {
      if (img.state !== 'added') {
        setTimeout(() => {
          pipelineCopy[index] = { ...img, state: 'removed' };
        }, 500);
        return true;
      }
      pipelineCopy[index] = { ...img, state: 'processed' };

      // wait for the transition to be ready
      const timeout = setTimeout(() => setPipeline(pipelineCopy), 100);
      timeouts.push(timeout);
      return false;
    });

    return () => {
      for (const timeout of timeouts) {
        clearTimeout(timeout);
      }
    };
  }, [pipeline]);

  return (
    <div className={styles.wrapper}>
      {pipeline.map((img) => (
        <img
          key={`${img.src}-${img.count}`}
          src={img.src || TRANSPARENT_PIXEL}
          alt={alt}
          className={classNameBuilder(styles.newImg, img.state !== 'added' && styles.visible)}
        />
      ))}
    </div>
  );
}

export default FadeImage;
