import { useContext } from 'react';
import { Button } from '@geberit/gdds';

// styles
import styles from './product-slider.module.scss';

// utils
import { ProductSliderContext } from './context';

export default function ProductSlideHandler() {
  const { slideHandlerRef, onButtonClick, onButtonDrag } = useContext(ProductSliderContext);

  const buttonHandlers = {
    onMouseUp: onButtonClick as (e: React.MouseEvent<HTMLButtonElement>) => void,
    onTouchStart: onButtonDrag,
    onMouseDown: onButtonDrag,
  };

  return (
    <div className={styles.slideHandler}>
      <div className={styles.slideHandlerButtonWrapper} ref={slideHandlerRef}>
        <Button isIcon symbol="ChevronLeft" stylingType="secondary" {...buttonHandlers} />
        <span className={styles.divider} onMouseDown={onButtonDrag} onTouchStart={onButtonDrag} />
        <Button
          isIcon
          symbol="ChevronRight"
          stylingType="secondary"
          {...buttonHandlers}
          className="right"
        />
      </div>
    </div>
  );
}
